import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Toolbar,
  LoadingIndicator,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableDataCell,
} from 'react95';
import {articlesBySlug} from './articles';
import conservative from './conservative.png';
import liberal from './liberal.png';
import {Link} from 'react-router-dom';

const StyledWindow = styled(Window)`
  && {
    display: block;
    max-width: 1000px;
    margin: 20px 0;
  }
`;

const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const allSlugs = Object.keys(articlesBySlug);
const getRandomSlugs = () => {
  let n = 10;
  let result = new Array(n);
  let len = allSlugs.length;
  let taken = new Array(len);

  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = allSlugs[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

const Tweets = () => {
  const [slugs, setSlugs] = useState(() => getRandomSlugs());
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    setTimeout(() => setCopied(false), 300);
  }, [copied]);

  const onGenerate = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSlugs(getRandomSlugs());
    }, 5000);
  };

  return (
    <StyledWindow resizable className="window">
      <StyledWindowHeader>
        <span>FakePoliticalTweets.exe</span>
      </StyledWindowHeader>
      <Toolbar>
        <div style={{position: 'relative', display: 'inline-block'}}>
          <Button
            onClick={onGenerate}
            disabled={loading}
            data-ga={`clickTweetsGenerate`}
          >
            Generate Fake Political Tweets
          </Button>
        </div>
      </Toolbar>
      <WindowContent>
        {loading ? (
          <LoadingIndicator isLoading />
        ) : (
          <Table>
            <TableHead>
              <TableRow head>
                <TableHeadCell>Political Orientation</TableHeadCell>
                <TableHeadCell>Tweet</TableHeadCell>
                <TableHeadCell disabled>Favs</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slugs.map(slug => {
                const tweet = articlesBySlug[slug].title;
                return (
                  <TableRow key={tweet}>
                    <TableDataCell style={{textAlign: 'center'}}>
                      <span role="img" aria-label="LEAF">
                        <Avatar
                          src={
                            [conservative, liberal][
                              Math.floor(Math.random() * 2)
                            ]
                          }
                          size={30}
                        />
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <Link to={`/${slug}`}>{tweet}</Link>
                    </TableDataCell>
                    <TableDataCell>
                      {Math.floor(Math.random() * 500)}
                    </TableDataCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </WindowContent>
    </StyledWindow>
  );
};

export default Tweets;
