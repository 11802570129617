import {Anchor} from 'react95';

const About = () => (
  <div>
    <h2>
      <strong>About Us</strong>
    </h2>

    <p>
      It started as a joke.
      <br />
      We initially made our own fake news generator to test a set of new AI
      language generation tools. But as we developed it, we realized that our
      newly created articles rocked! Some of them were extremely funny and
      others looked more real than actual articles written by real journalists.
    </p>

    <p>
      Friends and family loved our fake news generator and they asked for a
      public version of it.
      <br />A few weeks later, we published{' '}
      <Anchor href="https://www.pure-words.com">www.pure-words.com</Anchor>.
    </p>

    <p>
      Hope you like it, and have as much fun using our fake news generator as we
      had making it.
    </p>

    <quote>John & the Fabulous Three.</quote>
  </div>
);

export default About;
