import {useState} from 'react';
import styled from 'styled-components';
import {Tabs, Tab, TabBody, Window, WindowHeader, WindowContent} from 'react95';
import Terms from './terms';
import Privacy from './privacy';
import About from './about';
import Contact from './contact';

const StyledWindow = styled(Window)`
  && {
    margin: 50px auto;
    display: block;
    max-width: 1000px;
    width: 100%;
  }
`;

const tabs = [
  'contact-us',
  'about-us',
  'terms-and-cnditions',
  'privacy-policy',
];

const NavTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (e, value) => setActiveTab(value);

  return (
    <StyledWindow>
      <WindowHeader>{tabs[activeTab]}.exe</WindowHeader>
      <WindowContent>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab value={0} data-ga="clickAboutUs">
            About us
          </Tab>
          <Tab value={1} data-ga="clickContactUs">
            Contact us
          </Tab>
          <Tab value={2} data-ga="clickTermsAndConditions">
            Terms and Conditions
          </Tab>
          <Tab value={3} data-ga="clickPrivacyPolicy">
            Privacy Policy
          </Tab>
        </Tabs>
        <TabBody style={{height: 400, overflow: 'scroll'}}>
          {activeTab === 0 && <About />}
          {activeTab === 1 && <Contact />}
          {activeTab === 2 && <Terms />}
          {activeTab === 3 && <Privacy />}
        </TabBody>
      </WindowContent>
    </StyledWindow>
  );
};

export default NavTabs;
