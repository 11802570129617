import {Fragment, useMemo, useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Toolbar,
  TextField,
  LoadingIndicator,
} from 'react95';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as articles from './articles';
import conservative from './conservative.png';
import liberal from './liberal.png';

const images = {
  conservative,
  liberal,
};

const StyledWindow = styled(Window)`
  && {
    display: block;
    max-width: 1000px;
    width: 100%;
    margin: 20px 0;
  }
`;

const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Description = ({closeWindow, flavor}) => {
  const [initialTitle, initialDesc] = useMemo(
    () => articles[flavor][Math.floor(Math.random() * articles[flavor].length)],
    [],
  );

  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [article, setArticle] = useState(initialDesc);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    setTimeout(() => setCopied(false), 500);
  }, [copied]);

  const onGenerate = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const [t, a] = articles[flavor][
        Math.floor(Math.random() * articles[flavor].length)
      ];
      setTitle(t);
      setArticle(a);
    }, 5000);
  };

  const flavorCapitalized = flavor.charAt(0).toUpperCase() + flavor.slice(1);

  return (
    <StyledWindow resizable className="window">
      <StyledWindowHeader>
        <span>
          Fake{flavorCapitalized}News.exe{copied && ' - copied to clipboard!'}
        </span>
        <Button square onClick={closeWindow}>
          &#10005;
        </Button>
      </StyledWindowHeader>
      <Toolbar>
        <div style={{position: 'relative', display: 'inline-block'}}>
          <Button
            onClick={onGenerate}
            disabled={editing || loading}
            data-ga={`click${flavorCapitalized}`}
          >
            <Avatar src={images[flavor]} size={30} /> Generate Fake{' '}
            {flavorCapitalized} News
          </Button>
        </div>
        <Button
          onClick={() => setEditing(ed => !ed)}
          disabled={loading || !article}
          active={editing}
          data-ga="clickEdit"
        >
          Edit
        </Button>
        <CopyToClipboard text={article} onCopy={() => setCopied(true)}>
          <Button disabled={loading || !article} data-ga="clickCopyToClipboard">
            Copy to clipboard
          </Button>
        </CopyToClipboard>
      </Toolbar>
      <WindowContent>
        {editing ? (
          <Fragment>
            {title && (
              <TextField
                defaultValue={title}
                fullWidth
                onChange={e => setTitle(e.target.value)}
              />
            )}
            <TextField
              multiline
              rows={10}
              defaultValue={article}
              fullWidth
              onChange={e => setArticle(e.target.value)}
            />
            <Button
              onClick={() => setEditing(false)}
              style={{marginTop: 4}}
              data-ga="clickStopEdit"
            >
              Stop editing
            </Button>
          </Fragment>
        ) : loading ? (
          <LoadingIndicator isLoading />
        ) : (
          <Fragment>
            <h3>{title || 'NO TITLE!!?? ¯_( ͡❛ ͜ʖ ͡❛)_/¯'}</h3>
            <p>{article || 'NO CONTENT!! (╯ ͡❛ ͜ʖ ͡❛)╯┻━┻'}</p>
          </Fragment>
        )}
      </WindowContent>
    </StyledWindow>
  );
};

export default Description;
