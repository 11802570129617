import {Fragment, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components';
import {withRouter} from 'react-router-dom';
import Banner from './banner';
import Tweets from './tweets';
import {
  AppBar,
  Toolbar,
  Bar,
  Button,
  Tooltip,
  Window,
  WindowContent,
  WindowHeader,
} from 'react95';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import logo from './logo_koloJohnny.png';

const StyledWindow = styled(Window)`
  && {
    display: block;
    max-width: 1000px;
    width: 100%;
    margin: 20px 0;
  }
`;

const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
    margin: 0 auto;
    background: rgb(0, 128, 128);
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 50px auto;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 20px;
`;

const Sidebar = styled.div`
  width: 45%;
  margin: 0 20px;
  height: 100%;
`;

const Article = ({history, slug, title, description}) => {
  let location = useLocation();

  useEffect(() => {
    let script;
    debugger;
    switch (location.pathname) {
      case '/':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
          'https://msgose.com/pw/waWQiOjEwNjYzMTMsInNpZCI6MTA3ODIyMywid2lkIjoxNTgzNzgsInNyYyI6Mn0=eyJ.js';
        document.body.appendChild(script);
        break;

      case '/democrats-attack-harris-for-supporting-hospital-bill':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://iclickcdn.com/tag.min.js';
        script.setAttribute('data-zone', 3838514);
        document.body.appendChild(script);
        break;

      case '/trump-says-stimulus-would-return-willing-ownership':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.setAttribute('data-cfasync', 'false');
        script.src = 'https://www.linkonclick.com/a/display.php?r=4022279';
        document.body.appendChild(script);
        break;

      case '/president-trump-s-health-even-as-he-battles-coronavirus':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.setAttribute('data-cfasync', 'false');
        script.src = '//d20903hof2l33q.cloudfront.net/?lfohd=913732';
        document.body.appendChild(script);
        break;

      case '/biden-and-the-left-are-running-out-of-time-to-stop-trump-from-making-america-great-again':
        (function () {
          var uid = '295418';
          var wid = '599916';
          var pop_tag = document.createElement('script');
          pop_tag.src = '//cdn.popcash.net/show.js';
          document.body.appendChild(pop_tag);
          pop_tag.onerror = function () {
            pop_tag = document.createElement('script');
            pop_tag.src = '//cdn2.popcash.net/show.js';
            document.body.appendChild(pop_tag);
          };
        })();
        break;
      default:
      // nothing
    }
  }, [location]);
  const onClose = () => {
    history.push('/');
  };
  return (
    <Fragment>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <AppBar style={{zIndex: 9999}}>
          <Toolbar>
            <Tooltip text="Pure Words" enterDelay={100} leaveDelay={500}>
              <img src={logo} title="Pure Words" alt="Pure Words" width={200} />
            </Tooltip>
            <Bar size={35} style={{marginLeft: 50}} />
            <Button variant="menu" onClick={onClose} data-ga="clickStart">
              Start
            </Button>
            <Bar size={35} />
          </Toolbar>
        </AppBar>
        <Container style={{paddingTop: 80}}>
          <Content>
            <Banner id="ad-container-1" zoneId={39} width={768} height={120} />
            <StyledWindow resizable className="window">
              <StyledWindowHeader>
                <span>{slug}.exe</span>
                <Button square onClick={onClose}>
                  &#10005;
                </Button>
              </StyledWindowHeader>
              <WindowContent>
                <h3>{title || 'NO TITLE!!?? ¯_( ͡❛ ͜ʖ ͡❛)_/¯'}</h3>
                <p>{description || 'NO CONTENT!! (╯ ͡❛ ͜ʖ ͡❛)╯┻━┻'}</p>
              </WindowContent>
            </StyledWindow>
          </Content>
          <Sidebar>
            <Banner id="ad-container-2" zoneId={40} width={320} height={280} />
            <Tweets />
            <Banner id="ad-container-3" zoneId={41} width={320} height={280} />
          </Sidebar>
        </Container>
      </ThemeProvider>
    </Fragment>
  );
};

export default withRouter(Article);
