import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {articlesBySlug} from './articles';
import Article from './article';
import App from './App';

const slugs = Object.keys(articlesBySlug);

const Routes = () => {
  return (
    <Router>
      <Switch>
        {slugs.map(slug => {
          const {title, description} = articlesBySlug[slug];
          return (
            <Route path={`/${slug}`} key={slug}>
              <Article slug={slug} title={title} description={description} />
            </Route>
          );
        })}
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
