import {Fragment, useState, useEffect} from 'react';
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components';
import {useLocation} from 'react-router-dom';
import {AppBar, Toolbar, Bar, Button, Tooltip} from 'react95';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import Description from './description';
import Tweets from './tweets';
import Quote from './quote';
import Tabs from './tabs';
import Banner from './banner';
import logo from './logo_koloJohnny.png';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
    margin: 0 auto;
    background: rgb(0, 128, 128);
  }
`;

const quotes = [
  {
    body: (
      <div>
        “In this era of fake news and paid news artificial intelligence is more
        and more used as a political tool to manipulate and dictate common
        people, through big data, biometric data, and AI analysis of online
        profiles and behaviors in social media and smart phones. But the days
        are not far when AI will also control the politicians and the media
        too.”
      </div>
    ),
    size: 150,
  },
  {
    body: (
      <div>
        “Alternative facts and fake news are just other names for propaganda”
      </div>
    ),
    size: 100,
  },
  {
    body: <div>“Efectul știrii este mai important decât adevărul ei.”</div>,
    size: 100,
  },
  {
    body: (
      <div>
        “Choose to scrutinize every information you get, because some people say
        things to mislead you and some media houses if not all. Are bought to
        push and publish certain agendas.”
      </div>
    ),
    size: 120,
  },
  {
    body: (
      <div>
        <p>
          “It's so important to identify beliefs. Because once you identify [a
          negative belief], once you bring it into the light, you will see it
          doesn't belong to you:
        </p>
        <p>- That it came from your parents;</p>
        <p>- It came from your family;</p>
        <p>- It came from your society;</p>
        <p>- It came from your friends.</p>
        <p>And you bought into it. But it isn't yours.</p>
        <br />
        <p>Holding on to something that isn't yours is called theft.</p>
        <p>Don't be a belief thief!</p>
        <br />
        <p>Let go of what isn't yours.”</p>
      </div>
    ),
    size: 200,
  },
];

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 50px auto;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 20px;
`;

const Sidebar = styled.div`
  width: 45%;
  margin: 0 20px;
  height: 100%;
`;

function App() {
  const [open, setOpen] = useState(true);
  let location = useLocation();

  useEffect(() => {
    let script;
    debugger;
    switch (location.pathname) {
      case '/':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
          'https://msgose.com/pw/waWQiOjEwNjYzMTMsInNpZCI6MTA3ODIyMywid2lkIjoxNTgzNzgsInNyYyI6Mn0=eyJ.js';
        document.body.appendChild(script);
        break;

      case '/democrats-attack-harris-for-supporting-hospital-bill':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://iclickcdn.com/tag.min.js';
        script.setAttribute('data-zone', 3838514);
        document.body.appendChild(script);
        break;

      case '/trump-says-stimulus-would-return-willing-ownership':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.setAttribute('data-cfasync', 'false');
        script.src = 'https://www.linkonclick.com/a/display.php?r=4022279';
        document.body.appendChild(script);
        break;

      case '/president-trump-s-health-even-as-he-battles-coronavirus':
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.setAttribute('data-cfasync', 'false');
        script.src = '//d20903hof2l33q.cloudfront.net/?lfohd=913732';
        document.body.appendChild(script);
        break;

      case '/biden-and-the-left-are-running-out-of-time-to-stop-trump-from-making-america-great-again':
        (function () {
          var uid = '295418';
          var wid = '599916';
          var pop_tag = document.createElement('script');
          pop_tag.src = '//cdn.popcash.net/show.js';
          document.body.appendChild(pop_tag);
          pop_tag.onerror = function () {
            pop_tag = document.createElement('script');
            pop_tag.src = '//cdn2.popcash.net/show.js';
            document.body.appendChild(pop_tag);
          };
        })();
        break;
      default:
      // nothing
    }
  }, [location]);

  return (
    <Fragment>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <AppBar style={{zIndex: 9999}}>
          <Toolbar>
            <Tooltip text="Pure Words" enterDelay={100} leaveDelay={500}>
              <img src={logo} title="Pure Words" alt="Pure Words" width={200} />
            </Tooltip>
            <Bar size={35} style={{marginLeft: 50}} />
            <Button
              variant="menu"
              onClick={() => setOpen(true)}
              data-ga="clickStart"
            >
              Start
            </Button>
            <Bar size={35} />
          </Toolbar>
        </AppBar>

        {open ? (
          <Container style={{paddingTop: 80}}>
            <Content>
              <Banner
                id="ad-container-1"
                zoneId={39}
                width={768}
                height={120}
              />
              <Description
                closeWindow={() => setOpen(false)}
                flavor="liberal"
              />
              <Description
                closeWindow={() => setOpen(false)}
                flavor="conservative"
              />
            </Content>
            <Sidebar>
              <Banner
                id="ad-container-2"
                zoneId={40}
                width={320}
                height={280}
              />
              <Tweets />
              <Banner
                id="ad-container-3"
                zoneId={41}
                width={320}
                height={280}
              />
            </Sidebar>
          </Container>
        ) : (
          <Container style={{paddingTop: 80}}>
            {quotes.map((q, i) => (
              <Quote key={i} style={{width: q.size * 3, height: 200}}>
                {q.body}
              </Quote>
            ))}
          </Container>
        )}

        <Tabs />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
