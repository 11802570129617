import {memo, useRef, useLayoutEffect} from 'react';
import {Cutout} from 'react95';

const Banner = ({zoneId, id, width = '300px', height = '200px'}) => {
  const bannerRef = useRef();

  useLayoutEffect(() => {
    const body = `
    <script type="text/javascript">
      var m3_u = (location.protocol=='https:'?'https://postnews.buzz/revive/www/delivery/ajs.php':'http://postnews.buzz/revive/www/delivery/ajs.php');
      var m3_r = Math.floor(Math.random()*99999999999);
      if (!document.MAX_used) document.MAX_used = ',';
      document.write ("<scr"+"ipt type='text/javascript' src='"+m3_u);
      document.write ("?zoneid=${zoneId}");
      document.write ('&amp;cb=' + m3_r);
      if (document.MAX_used != ',') document.write ("&amp;exclude=" + document.MAX_used);
      document.write (document.charset ? '&amp;charset='+document.charset : (document.characterSet ? '&amp;charset='+document.characterSet : ''));
      document.write ("&amp;loc=" + escape(window.location));
      if (document.referrer) document.write ("&amp;referer=" + escape(document.referrer));
      if (document.context) document.write ("&context=" + escape(document.context));
      if (document.mmm_fo) document.write ("&amp;mmm_fo=1");
      document.write ("'></scr"+"ipt>");
    </script>
  `;

    bannerRef.current.contentDocument.open();
    bannerRef.current.contentDocument.write(
      `<head></head><body>${body}</body>`,
    );
    bannerRef.current.contentDocument.close();
  }, []);

  return (
    <Cutout style={{width: `${width + 20}px`, height: `${height + 20}px`}}>
      <iframe
        ref={bannerRef}
        id={id}
        width={width}
        height={height}
        className="ad-container"
      >
        {
          // <script
          //         type="text/javascript"
          //         src={`https://postnews.buzz/revive/www/delivery/ajs.php?zoneid=${zoneId}&amp;cb=${Math.floor(
          //           Math.random() * 99999999999,
          //         )}&amp;charset=UTF-8&amp;loc=https%3A//pure-words.com/`}
          //       ></script>
        }
      </iframe>
    </Cutout>
  );
};

export default memo(Banner, () => true);
