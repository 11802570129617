import {useState} from 'react';
import {Fieldset, TextField, Button} from 'react95';

const Contact = () => {
  const [value, setValue] = useState('');

  const onChange = e => setValue(e.target.value);
  const onSubmit = e => {
    setValue('We got you. Thank you ;)');

    setTimeout(() => {
      setValue('');
    }, 1000);
  };

  return (
    <div>
      <h2>
        <strong>Contact Us</strong>
      </h2>

      <p>
        Dear friend, have any comment, idea, or suggestion?
        <br />
        We welcome feedback and love to meet new people, drop us a line!
      </p>
      <br />
      <Fieldset label="Your message:">
        <TextField
          placeholder="Write us some pure words..."
          fullWidth
          value={value}
          onChange={onChange}
          multiline
          rows={7}
        />
        <br />
        <Button
          onClick={onSubmit}
          data-ga="clickSendMessageToContactUs"
          disabled={!value}
        >
          Send
        </Button>
      </Fieldset>
    </div>
  );
};

export default Contact;
