import {Cutout, Window, WindowContent} from 'react95';

const Quote = ({children, style}) => (
  <Window style={{margin: 20}}>
    <WindowContent>
      <Cutout style={style}>{children}</Cutout>
    </WindowContent>
  </Window>
);

export default Quote;
